import english from "./static/data/en.json";
import french from "./static/data/fr.json";
import spanish from "./static/data/es.json";

export default defineI18nConfig(nuxt => ({

      legacy: false,
      locale: "en",
      messages: {
        en: english.data,
        fr: french.data,
        es: spanish.data,
      },

}))